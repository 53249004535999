import {
  PUBLIC_SUPABASE_ANON_KEY,
  PUBLIC_SUPABASE_URL,
} from "$env/static/public"
import {
  createBrowserClient,
  createServerClient,
  isBrowser,
} from "@supabase/ssr"
import type { Database } from "../DatabaseDefinitions.js"
import { load_helper } from "$lib/load_helpers"
import type { LayoutLoad } from "./$types"

/*
 * Always grab a supabase instance plus the current session and user at
 * the root, because they're all used pretty much everywhere. DO NOT add a
 * dependency on `url` here as this would break caching and force this layout
 * to be re-calculated for every single page.
 *
 * `data` comes from +layout.server.ts
 */

export const load: LayoutLoad =
  async ({ fetch, depends, data }) => {
    depends("supabase:auth")

    const supabase = isBrowser()
      ? createBrowserClient<Database>(
        PUBLIC_SUPABASE_URL,
        PUBLIC_SUPABASE_ANON_KEY,
        {
          global: {
            fetch,
          },
        },
      )
      : createServerClient<Database>(
        PUBLIC_SUPABASE_URL,
        PUBLIC_SUPABASE_ANON_KEY,
        {
          global: {
            fetch,
          },
          cookies: {
            getAll() {
              return data.cookies
            },
          },
        },
      )

    const { session, user } = await load_helper(data.session, supabase)
    const { data: aal } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()

    return {
      supabase,
      session,
      user,
      amr: aal?.currentAuthenticationMethods,
    }
  }

export const _hasFullProfile = (
  profile: Database["public"]["Tables"]["profiles"]["Row"] | null,
) => {
  console.log("Checking if profile is full: ", profile)
  return profile && profile.full_name && profile.company_name && profile.website
}
